import React, { useState } from 'react';

   

const Blog = () => {
  

  return (
    <div className="container">
        <h1>Blodg</h1>
        
        
    </div>
  );
};

export default Blog;
