import React, { useState } from 'react';
import { 
   
    BothInfo,
    CTASection
} from '../ui-components';




const Home = () => {
  

  return (
    <div className="container">
        <CTASection/>
        <BothInfo/>
        
        
        
    </div>
  );
};

export default Home;
