import React, { useState } from 'react';



const Contact = () => {
  

  return (
    <div className="container">
        
        
    </div>
  );
};

export default Contact;
