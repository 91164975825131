import React, { useState } from 'react';

import {
    BothInfo
} from '../ui-components'


const About = () => {
  

  return (
    <div className="container">
        <BothInfo/>
        
    </div>
  );
};

export default About;
