/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "29d44a6d18ed44e9adde092faf697b85",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "29d44a6d18ed44e9adde092faf697b85",
            "region": "us-east-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://5gdx5lfogve2zo6gpe7ajbmpga.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2ql42l3nhnatbfy6znfa6rnofi",
    "aws_cognito_identity_pool_id": "us-east-2:4336dc0d-af05-46bc-830b-ce5810e651a4",
    "aws_cognito_region": "us-east-2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": []
};


export default awsmobile;
