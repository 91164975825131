// src/pages/TOS.jsx
import React from 'react';

function TOS() {
  return (
    <div className="container">
      <h2>TOS Page</h2>
      <p>Buzzly Inc. (“Buzzly” “we,” “our,” and/or “us”) provides services that enable an End User to connect their account (each an “Account”) on various third party creator economy platforms, including social networking platforms that allow its users to share pictures, videos or other content  (each, a “Platform”), to web sites and applications such as the software-as-a-service or application run by You.  

These Terms of Service are an agreement between you (“You”, “you”, “your” and/or “Client”) and Buzzly. By accepting these Terms of Service or downloading and using Buzzly’s API and / or related documentation or by using the Services, you agree to these Terms of Service and certify that you have all necessary rights to do so. If you are accepting on behalf of another Person, you represent and warrant that: (i) you have full legal authority to bind such Person to these Terms of Service; (ii) you have read and understood these Terms of Service; and (iii) you agree to these Terms of Service on behalf of the Person that you represent. If you do not have the legal authority to bind the Person on whose behalf you are accepting these Terms of Service, please do not accept the Agreement or access Buzzly’s Offering.

These Terms of Service must be read with the Work Order executed between You and Buzzly. In case of any inconsistency between these Terms of Service and a Work Order, the provisions of these Terms of Service shall prevail unless expressly and specifically stated otherwise in the Work Order. 

1. Definitions.
1.1 “Affiliate” of a Party means any Person that directly or indirectly Controls, is Controlled by, or is under common Control with, such Party.
1.2 “Agreement” means these Terms of Service, the Work Order any terms, conditions and requirements of any of Buzzly’s Offering, terms and conditions in the Documentation and the Privacy Policy.
1.3 “Anonymised Data” means the following data that has been anonymised by removing all personal / identifying information and information about the source of such data (whether or not aggregated with any other data relating to other clients and users of Buzzly’s Offering): (i) data relating to Your and End Users’ usage of any part or all of Buzzly’s Offering, (ii) data provided by you and / or the End User, and (iii) End User Data.
1.4 “API” means Buzzly’s proprietary application programming interface.
1.5 “API Key” means a unique identifier used to authenticate a Person to access the API made available by Buzzly to Client. 
1.6 “Client’s Application” means a website, software, and/or software-as-a-service application that the Client uses to interact with an End User and/or to sell products or services to an End User, which integrates some or all aspects of Buzzly’s Offering. 
1.7 “Client Data” means non-public account administrative data provided by Client to Buzzly including, billing information, account profile setup information, and similar administrative information, but expressly excluding End User Data and Anonymised Data.
1.8“Confidential Information” means any proprietary and non-public information, or any information pertaining to the products, services, or operations of a Party provided to or accessed by the other Party. Confidential Information of Buzzly includes without limitation: (i) Buzzly’s APIs, SDKs, software, technology; (ii) technical or business information, past, present and future research, products (prototype or otherwise), sales and marketing techniques and plans, financial information, information related to customer, vendors, price lists, pricing policies, advertising strategies, information concerning Buzzly’s employees; (iii) Buzzly’s intellectual property whether registered, unregistered, pending registration, registrable or otherwise; and (iv) materials, drawings, specifications, techniques, models, data, manuals, documentation, processes, procedures, algorithms, equipment, know-how created by Buzzly.  “Confidential Information” does not include information which (a) is or becomes public knowledge without any breach of this Agreement by the receiving Party; (b) is documented as being known to the receiving Party prior to its disclosure; (c) is independently developed by the receiving Party without reference or access to the Confidential Information of the disclosing Party; or (d) is obtained by the receiving Party without restrictions on use or disclosure from a third party.
1.9 “Control” means the power, direct or indirect, whether by ownership of equity or voting interests, contract or otherwise to (i) direct or cause the direction of the management and policies of the Person; or (ii) appoint the majority of the board or other governing body of the Person. Ownership of more than 50% of the voting securities or other ownership interest in a Person shall be deemed to constitute Control.
1.10“Documentation” means documentation provided by Buzzly relating to Buzzly’s Offering (e.g., user manuals and online help files), including the documentation available at www.getBuzzly.com.
1.11 “End User” means the Person whose information is being accessed or obtained by Buzzly for the Client. 
1.12 “End User Data” means any data, information, and materials about an End User provided to Buzzly and/or the Client by an End User through Buzzly’s Offering or through any Platform, or otherwise obtained by Buzzly, including without limitation, information about the End User’s Account (e.g., login credentials), an individual’s name, address, telephone number, email address, financial information, service ratings and connections (by whatever name it is called, for instance, followers, subscribers, and/or friends). To further understand what kinds of data Buzzly collects from or about an End User, please refer to our Privacy Policy. 
1.13 “Infringement Claim” has the meaning assigned to the term in Clause 14.2.
1.14 “Intellectual Property” means all patent rights (including utility models), copyrights, moral rights, trademark and service mark rights, trade secret rights, and any other similar proprietary or intellectual property rights (registered or unregistered) throughout the world, together with all applications for any of the foregoing.
1.15 “Party” means Buzzly and Client individually and “Parties” means Buzzly and Client collectively. 
1.16 “Person” means any individual, partnership, limited liability partnership, limited liability company, body corporate, corporation, association, society, trust or other entity or organization, whether or not a juridical person.
1.17 “Buzzly’s Offering” means Buzzly’s API, API Key, Documentation, SDK and related tools (if any) and the Services.
1.18 “Privacy Policy” means Buzzly’s privacy policy available at https://www.getBuzzly.com/privacy-policy.
1.19 “Purpose” means the purpose for which the Client can use Buzzly’s Offering as set out in the Work Order.
1.20 "Representatives” has the meaning assigned to the term in Clause 12.
1.21 “SDK” means the software development kit provided by Buzzly that allows a Person to access the API. 
1.22 “Services” means the services set forth in the Work Order.
1.23 “Term” means the period for which Buzzly’s Offering will be provided to the Client as stated in the applicable Work Order.
1.24 “Terms of Service” means these terms of service, and any other terms, conditions and requirements set out in any of Buzzly’s Offering, including without limitation the Documentation.
1.25 “Work Order” means a document specifying the services to be provided under this Agreement that is entered into between Client and Buzzly, including any addenda and supplements thereto.
2. Services. Subject to the terms of this Agreement and payment of the applicable fee, Buzzly will provide the Services to the Client. Client confirms that it has read and understood, and shall comply with these Terms of Service, which includes for the avoidance of doubt, any terms, conditions and requirements of any of Buzzly’s Offering, and any terms and conditions in the Documentation. 
3. Grant of Rights. Subject to the terms of this Agreement and payment of the applicable fee, Buzzly grants to the Client a non-exclusive, non-transferable, non-sublicensable right and license to, during the Term: 
i) (a) download 1 (one) copy of the API Key, (b) use the API Key to access the API through the Client Application solely for the purpose of obtaining End User Data as permitted by the End User for the Purpose, and (c) make a reasonable number of copies of the API Key solely for testing, backup, or archival purposes; and
ii) reproduce, without modification, and internally use the Documentation solely in connection with Client’s use of Buzzly’s Offering in accordance with this Agreement.
4. Fees and Payment. The consideration payable by Client to Buzzly and terms of payment shall be as per the Work Order. 
5. Modification of Buzzly’s Offering. Buzzly is entitled to modify Buzzly’s Offering from time to time by adding, deleting, or modifying features, tools, content and any other thing that is included in Buzzly’s Offering.
6. Amendment of the Agreement. This Agreement may be amended, modified and supplemented from time to time by Buzzly, by posting the amended agreement to Buzzly’s website and notifying the Client. Any use of Buzzly’s Offering after an update has been made and posted to the website and Client has been notified, shall be deemed Client’s acceptance of the amended terms.
7. Restrictions on Use. 
7.1 Except as expressly permitted in this Agreement, the Client will not (and will not authorize, permit, or encourage any third party to): (i) allow anyone other than Client’s authorized personnel (in accordance with the terms of this Agreement) to access and use Buzzly’s Offering; (ii) share the login credentials provided by Buzzly or API Key with any Person; (iii) reverse engineer, decompile, disassemble, or otherwise attempt to discern the source code or interface protocols of Buzzly’s Offering; (iv) modify, adapt, copy or translate Buzzly’s Offering; (v) resell, distribute, or sublicense the API Keys, SDK or any other portion of Buzzly’s Offering; (vi) use Buzzly’s Offering for the benefit of any Person; (vii) save, store, or archive any portion of Buzzly’s Offering (including, without limitation, any data contained therein) outside Buzzly’s Offering other than those outputs generated through the intended functionality of Buzzly’s Offering; (viii) remove or modify any proprietary markings or restrictive legends placed by Buzzly; (ix) use Buzzly’s Offering in violation of any applicable law, or to build a competitive product or service, or for any purpose other than the Purpose; (x) circumvent any processes, procedures, or technologies that Buzzly has put in place to safeguard Buzzly’s Offering or its property; 
7.2 Client agrees that they will at all times comply with the Terms of Service and Privacy Policy of other platforms and that they will be bound by such terms in addition to Buzzly’s own terms, and not make any act or omission, whether directly or indirectly through Buzzly’s Offering, that may constitute a violation  of, any Platform’s terms, conditions and policies including without limitation: 
i) YouTube: YouTube: https://www.youtube.com/yt/about/policies/#community-guidelines
https://developers.google.com/youtube/terms/api-services-terms-of-service
https://www.youtube.com/t/terms
ii) Twitter: https://twitter.com/privacy, https://developer.twitter.com/en/developer-terms/agreement-and-policy and https://twitter.com/en/tos
iii) Facebook: https://www.facebook.com/policy.php, https://developers.facebook.com/devpolicy/, https://developers.facebook.com/terms/dfc_platform_terms/, https://www.facebook.com/terms.php and https://www.facebook.com/page_guidelines.php
iv) Instagram: https://help.instagram.com/402411646841720, https://help.instagram.com/325135857663734, https://help.instagram.com/581066165581870,  and https://help.instagram.com/478745558852511
v) Google: http://www.google.com/policies/privacy and https://policies.google.com/terms?hl=en-US.
7.3 The Client shall not use Buzzly’s Offering to access, store, distribute or transmit any material that: (i) is unlawful, wrongful, tortious, libellous, slanderous or defamatory; (ii) infringes any intellectual property rights, privacy rights, publicity rights or any other proprietary or other rights of any Person; (iii) facilitates or promotes illegal activity or illegal goods/services; (iv) is false or misleading or is an unfair trade practice; (v) introduces any harmful, malicious, or hidden code, programs, procedures, routines, or mechanisms into Buzzly’s Offering or any third party’s platform, service or property, including, without limitation, trojan horses and viruses; or (vi) Buzzly believes is inappropriate in any manner. 
7.4 In the event of a breach of this Clause 7, Buzzly reserves the right, without liability or prejudice to its other rights, at its sole discretion, to do all or any of the following: (i) Disable End User’s and/or Client’s access to part or all of Buzzly’s Offering (temporarily or permanently); (ii) Terminate this Agreement; (iii) Take necessary or requisite legal action against you; (iv) Remove any material that is, in the opinion of Buzzly, contrary to the terms of the Agreement.
7.5 Client will ensure that its employees and permitted agents who access and use Buzzly’s Offering comply with this Agreement and will be liable for their breach of this Agreement. Client is responsible to ensure that no unauthorised Person accesses or uses Buzzly’s Offering.
8. Third parties. Buzzly is not responsible for the procurement, supply, maintenance or security of any third party or Client infrastructure, hardware or software which is being used or is required by the Client to access Buzzly’s Offering, nor for any errors, delays or other similar events or things caused due to any third party or Client. Buzzly is not responsible for any delay or breach, defect or error, if they arise from any breach, failure or delay by the Client, or its representatives, affiliates, agents or any third party including those third parties appointed by the Client to perform their respective obligations or provide the relevant products, services or infrastructure, or any other reason beyond the reasonable control of Buzzly.
9. Retention and Use of Data by Buzzly. 
9.1 Anonymised Data. Buzzly is entitled to create Anonymised Data. Buzzly is also entitled to copy, use, store, modify, display, transfer, sell, rent, license, or otherwise deal with and exploit Anonymised Data for any purpose during the Term and at all times after the end of the Term. 
9.2 Client Data. Client grants Buzzly a worldwide, non-exclusive, royalty-free, sub-licensable, fully-paid license, to store, process, use, transfer, reproduce and modify Client Data, for the purpose of providing and supporting Buzzly’s Offering to the Client and End Users, during the Term. Buzzly will store, process, use, transfer, reproduce and modify Client Data in the manner and for the period required by applicable law. 
9.3 Feedback. Buzzly will be entitled to use, store, modify, exploit, transfer or otherwise deal with all feedback and suggestions provided by the Client with respect to Buzzly’s Offering for any and all purposes. Client grants Buzzly a perpetual, irrevocable, royalty-free license to do any or all of the aforesaid, without any form of compensation or attribution to Client or any third party. 
9.4 Logs and Usage Data. Buzzly may view, use, copy, store, modify, analyse, or otherwise deal with and exploit logs and data relating to the Client’s usage of Buzzly’s Offering for any purpose including sales, marketing and product development during the Term and at all times after the end of the Term.
9.5 Personal Data. Buzzly’s treatment of personal data is covered under the Privacy Policy. Client hereby accepts the same on behalf of itself and its representatives.
10. Consent. 
10.1 Each Party shall be responsible for obtaining all consents and permissions from the End User or any other third party, for such Party’s collection, storage, use, reproduction, transfer, use and any other processing or dealing with data as required by the applicable law. 
10.2 The Client will obtain all consents from the End User to enable the Client to obtain End User Data from Buzzly, and use or otherwise deal with any End User Data. Buzzly is not responsible for obtaining any consents and permissions for any use or dealing of data by the Client.  
10.3 Each Party will publish and maintain its own privacy policy (in compliance with any applicable law) that will set out the terms on which any third party data  or End User Data is collected, stored, used, processed or otherwise dealt with. Buzzly shall not be responsible for any violation of applicable law as a result of any use, processing or other dealing of third party(ies)’s data (including End User Data) by the Client. 
10.4 If Buzzly needs to notify End Users of, or obtain consent or authorisation of End Users to, Buzzly’s end user terms, privacy policy, terms and conditions of any of Buzzly’s Offering, or any other document or agreement (referred to as the “Contract Documentation”) or any change, modification, supersession, replacement, updation deletion or removal of any Contract Documentation or part thereof, then the Client will assist Buzzly in providing such notification to or obtaining such consent from End Users (including through Client’s Application). 
10.5 The Client acknowledges that Buzzly’s ability to perform the Services will be adversely affected or Buzzly will not be able to perform Services, if End Users do not provide consents and authorisations required by Buzzly or if End Users withdraw any consents / authorisations. 
10.6 Client further represents and warrants and covenants to Buzzly that Client owns and shall own the Client Data, or has and shall have the necessary licenses, rights, consents, and permissions to authorize Buzzly to use the Client Data in accordance with this Agreement. 
11. Dependencies on Platform. Buzzly will connect with and extract End User Data from Platforms only as permitted and authorised by the End User. Buzzly may not have any contractual relationship with Platforms and is only providing a tool or conduit in the form of Buzzly’s Offering to allow the Client and End User to access the End User Data on Platforms as per their requirement and express consent of the End User. Buzzly will need to comply with the terms, conditions and policies of Platforms, including:
i) YouTube: https://www.youtube.com/yt/about/policies/#community-guidelines, https://developers.google.com/youtube/terms/api-services-terms-of-service and www.youtube.com/t/terms
ii) Twitter: https://twitter.com/privacy, https://developer.twitter.com/en/developer-terms/agreement-and-policy and https://twitter.com/en/tos
iii) Facebook: https://www.facebook.com/policy.php, https://developers.facebook.com/devpolicy/, https://developers.facebook.com/terms/dfc_platform_terms/, https://www.facebook.com/terms.php and https://www.facebook.com/page_guidelines.php
iv) Instagram: https://help.instagram.com/402411646841720, https://help.instagram.com/325135857663734, https://help.instagram.com/581066165581870,  and https://help.instagram.com/478745558852511
v) Google: http://www.google.com/policies/privacy and https://policies.google.com/terms?hl=en-US.

Client agrees and acknowledges that Buzzly’s Offering will be limited by and subject to the terms conditions and policies of the Platforms, and will not require Buzzly to do or omit to do, anything to violate or circumvent the terms, conditions and policies of the Platforms.
12. Confidentiality. Receiving Party will protect and preserve the Confidential Information as confidential, using no less care than that with which it protects and preserves its own confidential and proprietary information (but in no event less than a reasonable degree of care), and will not use the Confidential Information for any purpose except to perform its obligations and exercise its rights under this Agreement. The receiving Party may disclose, distribute, or disseminate the Confidential Information to any of its officers, directors, members, managers, partners, employees, (its “Representatives”), provided that such Representative has a ‘need to know’ the Confidential Information for the performance of this Agreement and such Representative is bound by confidentiality obligations at least as restrictive as those contained herein. The receiving Party will not disclose, distribute, or disseminate the Confidential Information to any third party, other than its Representatives, without the prior written consent of the disclosing Party. The receiving Party will at all times remain responsible for any violations of this Agreement by any of its Representatives. If the receiving Party is legally compelled to disclose any of the Confidential Information, the receiving Party will provide the disclosing Party prompt prior written notice of such requirement so that the disclosing Party may seek a protective order or other appropriate remedy and/or waive compliance with the terms of this provision. In any event, the receiving Party may furnish only that portion of the Confidential Information which is legally required to be disclosed and will use commercially reasonable efforts to ensure that confidential treatment will be afforded such disclosed portion of the Confidential Information.
13. Ownership of Intellectual Property. No transfer of ownership of any Intellectual Property is contemplated by way of this Agreement other than the limited right to use Buzzly’s Offering granted by Buzzly to the Client for the Term. Buzzly shall retain ownership of all rights and title over the API, SDK, API Keys, documentation, software, technology and all other aspects of Buzzly’s Offering, including without limitation, any improvements, modifications or adaptations irrespective of whether such right is registered or not in accordance with the applicable law.
14. Indemnity. 
14.1 Indemnification by the Client. The Client will indemnify and hold harmless Buzzly against any and all claims (including third party claims), losses, damages, and costs of any nature arising out of any breach of Clause 7.2, Clause 7.3, Clause 10 (Consent),  and/or Clause 21Error: Reference source not found (Compliance with Applicable Law and Restrictions on Use of End User Data).
14.2 Indemnification by Buzzly. Buzzly will indemnify and hold harmless the Client against all costs, expenses and damages that are adjudicated to be payable by Client in  any  third party claims (including reasonable attorney’s fees and expenses) arising out of (i) any infringement of a third party’s United States patents, or copyrights as a result of the Client’s use of Buzzly’s Offering in accordance with this Agreement (“Infringement Claims”), (ii) breach by Buzzly of Clause 10 (Consent),  and/or Clause 21Error: Reference source not found (Compliance with Applicable Law and Restrictions on Use of End User Data).
14.3 Infringement Claims. Buzzly shall not be obligated to indemnify, defend, or hold the Client harmless with respect to any Infringement Claim to the extent: 
i) the Infringement Claim arises from or is based upon the Client or any Client’s personnel, End User’s use of: 
          (a) Buzzly’s Offering not in accordance with this Agreement or the End User Agreement (as applicable), or applicable law; or 

         (b) any unauthorized modifications, alterations, or implementations of Buzzly’s Offering made by the Client or any other Person; or

ii) the Infringement Claim arises from any use of Buzzly’s Offering for which Buzzly’s Offering was not designed. 
14.4 If Buzzly reasonably determines that Buzzly’s Offering or any portion thereof are likely to be the subject of an Infringement Claim, Buzzly will: (i) procure for the Client the right to continue to use Buzzly’s Offering as provided in this Agreement, (ii) replace the infringing components of Buzzly’s Offering with other components of equivalent functionality, or (iii) suitably modify Buzzly’s Offering or parts thereof so that they are non-infringing and functionally equivalent. If none of the foregoing options are possible on commercially reasonable terms, Buzzly may terminate this Agreement without further liability to the Client and refund a pro-rated portion of any pre-paid fees. This Clause 14.4, together with the indemnity provided under Clause 14.2, states the Client’s sole and exclusive remedy, and Buzzly’s sole and exclusive liability, regarding any Infringement Claim.
14.5 Procedure for indemnification. Any Party with a possible claim for indemnity under this Clause 14 shall promptly notify the indemnifying Party of the potential claim, and shall cooperate with the indemnifying Party, at the indemnifying Party’s cost, in the investigation and defense of the same. The indemnifying Party shall have sole control over the defense and/or settlement of any such claim, but the indemnified Party may participate at its own expense with counsel of its choosing. The indemnifying Party will not settle any such claim without the indemnified Party’s prior written consent (unless such settlement does not impose any obligations, admissions, or liabilities upon the indemnified party). 
15. Limitation of Liability. 
15.1 The maximum liability of either Party for all claims arising out of or in any way connected to this Agreement will not exceed the fees paid by Client to Buzzly during the twelve (12) months preceding the last of such claims, less any amounts paid in respect of any such claims. This Clause 15.1 shall not limit or exclude Client’s liability for its payment obligations hereunder and each Party’s liability for its indemnification obligations under Clause 14.
15.2 Neither Party shall be liable to the other (or to any person claiming rights derived from the other party’s  rights) or to any third party, in contract, tort or otherwise, for any special, indirect, remote, punitive, consequential, or incidental damages of any kind including, without limitation, any lost profits, revenue or business opportunity, even if such Party has been advised of the possibility of such damages. 
16. Representations and Warranties. Each Party represents and warrants to the other that (i) this Agreement has been duly executed and delivered and constitutes a binding agreement enforceable against the executing Party in accordance with its terms; (ii) no authorization or approval from any third party is required in connection with the execution, delivery, or performance of this Agreement by the executing Party; and (iii) the execution, delivery, and performance of this Agreement by the executing Party do not violate the laws of any jurisdiction or the terms or conditions of any other agreement to which it is a party or by which it is otherwise bound.
17. Disclaimers. OTHER THAN AS SET OUT IN THIS AGREEMENT Buzzly MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO Buzzly’S OFFERING, THE DOCUMENTATION, SERVICES OR OTHERWISE RELATING TO THIS AGREEMENT, AND SPECIFICALLY DISCLAIMS ALL IMPLIED AND STATUTORY WARRANTIES, INCLUDING THE IMPLIED WARRANTIES OF NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, MERCHANTABILITY, ACCURACY, TITLE, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. Buzzly’S OFFERING AND DOCUMENTATION ARE PROVIDED “AS IS” AND “AS AVAILABLE.” Buzzly DOES NOT WARRANT THAT THE DOCUMENTATION OR Buzzly’S OFFERING WILL SATISFY CLIENT’S REQUIREMENTS, ARE WITHOUT DEFECT OR ERROR, OR THAT THE OPERATION OF Buzzly’S OFFERING WILL BE UNINTERRUPTED. Buzzly CANNOT GUARANTEE THE CONTINUED AVAILABILITY OF Buzzly’S OFFERING, AND MAY CEASE PROVIDING THEM. FURTHER, Buzzly MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING ANY PLATFORM OR DATA BEING MAINTAINED BY ANY THIRD PARTY, ITS ACCURACY, COMPLETENESS, OR CURRENTNESS. Buzzly DOES NOT CONTROL OR HAVE ANY CONTRACTUAL RELATIONSHIP WITH ANY THIRD PARTY MAITAINING THE DATA THAT IS BEING ACCESSED BY THE CLIENT OR THE END USER, AND HAS NO OBLIGATION TO, VERIFY OR REVIEW SUCH DATA. CLIENT’S RELIANCE ON ANY THIRD PARTY DATA IS AT ITS OWN RISK. 
18. Termination. 
18.1 If a Party is in breach of the terms of this Agreement, the other Party shall have the right to terminate the Agreement if the Party in breach does not remedy the breach within a period of 30 days from the date on which the written notice of the breach is received. These Terms of Service shall be co terminus with the Work Order, and shall terminate on completion of the Services specified in the Work Order. 
18.2 Notwithstanding Clause 18.1, Buzzly may terminate the Agreement with immediate effect if the Client infringes upon Buzzly’s intellectual property rights or violates Clause 3 (Grant of Rights), Clause 7 (Restrictions on Use), Clause 10 (Consent), or Clause 21 (Compliance with Applicable Law and Restrictions on Use of End User Data).
18.3 Effect of termination.
i) Termination of these Terms of Service will automatically terminate all Work Orders executed between the Parties. 
ii) The Client shall immediately make payment of all outstanding and accrued fees and payments to Buzzly that are pending as on the effective date of termination.
iii) Any accrued rights and obligations of the Parties upto the effective date of termination shall not be affected.
iv) The Client must return/destroy all originals and copies of the API, API Key, SDK,  Documentation and any other Buzzly Offering, and all Confidential Information of Buzzly and confirm the same to Buzzly on the effective date of termination or expiry of this Agreement.
v) Buzzly shall return/destroy all Client Data and Confidential Information of the Client on the effective date of termination or expiry of this Agreement. 
18.4 Survival. The following shall survive termination/ expiry of the Agreement: Clauses 12 (Confidentiality), 14 (Indemnity), 15 (Limitation of Liability), 17 (Disclaimer), 21 (Compliance with applicable law and restriction on use of End User Data), 20 (Governing Law, Jurisdiction and Dispute Resolution), 22 (Notices) and 28 (Interpretation).
19. Publicity. Buzzly is entitled to publicly list and name Client as a customer of Buzzly and use Client’s name, trademarks, service marks, trade names, and logos to identify Client as a client, on its websites, in marketing and promotional material and in other communications with the public, including existing or potential Buzzly customers, resellers, or investors.
20. Governing Law, Jurisdiction and Dispute Resolution. 
20.1 This Agreement is governed by the laws of the State of California, without reference to its conflict of laws principles. The United Nations Convention on Contracts for the International Sale of Goods will not apply to this Agreement. Except as set out in Section 20.4, all claims arising under this Agreement will be litigated exclusively in the state and federal courts of San Francisco County. The Parties submit to the jurisdiction in those courts.
20.2 Jury waiver. With respect to any claim or dispute under this Agreement, the Parties hereby irrevocably waive all right to trial by jury in any court in any action for the adjudication of any claim or dispute arising under this agreement.
20.3 Class action waiver. The Client waives any right to assert any claims against Buzzly as a representative or member in any class or representative action, except where such waiver is prohibited by law or deemed by a court of law to be unenforceable. To the extent the Client is permitted by law or court of law to proceed with a class or representative action against Buzzly, the Client agrees that: (i) it shall not be entitled to recover attorneys’ fees or costs associated with pursuing the class or representative action (not withstanding any other provision in this agreement); and (ii) the Client will not submit a claim or otherwise participate in any recovery secured through the class or representative action.
20.4 Equitable relief. You acknowledge and agree that in the event of a breach or threatened violation of Buzzly’s intellectual property rights and confidential and proprietary information by you, Buzzly will suffer irreparable harm and will therefore be entitled to injunctive relief to enforce this Agreement. Buzzly may, without waiving any other remedies under this Agreement and under law, seek from any court having jurisdiction any interim, equitable, provisional, or injunctive relief that is necessary to protect Buzzly’s rights and property. 
21. Compliance with applicable law and restriction on use of End User Data. Each Party confirms that it will comply with all applicable law in connection with exercising its rights or performing its obligations under this Agreement. Each Party confirms that, in connection with Client’s use of Buzzly’s Offering, End User Data will be transmitted or made available in a manner that neither Buzzly nor Client will alter the substance of the End User Data. Each Party will be acting only as a service provider and/or a conduit, and not as a consumer-reporting agency, in connection with the End User Data processed and transmitted under this Agreement. Buzzly shall collect, use, and transmit End User Data only as requested by the Client and as requested and authorized by the owner of such information. Each Party confirms that neither Party is a reseller of data but simply provides the service of transferring data as requested and authorized by the owner thereof from one Person to the Person directed and authorized to receive the data by the owner thereof. Each Party confirms that End User Data may contain personal and/or sensitive information and therefore each Party will employ commercially reasonable physical, administrative, and technical safeguards to secure the End User Data from unauthorized use or disclosure.
22. Notices. Buzzly will send notices or communications to you through the email registered by you with Buzzly. All notices and communications to Buzzly under this Agreement must be sent to Buzzly Inc. 38350 Fremont Blvd, Fremont California, 94536 or to support@getBuzzly.com. Either Party may update its notice address by notice to the other Party in accordance with this provision.
23. Force Majeure. Neither Party will be liable for any delay or failure to perform as required by this Agreement (except for payment obligations) as a result of any cause or condition beyond its reasonable control, so long as it uses reasonable efforts to avoid or remove those causes of delay or non-performance.
24. Severability. If any provision of this Agreement is held to be illegal or unenforceable then such provision shall be severed from the Agreement without affecting the enforceability of the remainder of the Agreement. 
25. Relationship. Nothing in this Agreement shall give rise to any relationship of partnership, joint venture or profit sharing in the nature of partnership between the Parties. No one other than a Party to this Agreement shall have any right to enforce any of its terms.
26. Assignment. This Agreement shall not be transferred or assigned by the Client without Buzzly’s prior written consent. Buzzly may assign or transfer this Agreement to its Affiliates or in connection with a merger, acquisition, sale of business/undertaking, reorganization or other change of control. 
27. Subcontracting. Buzzly may subcontract its obligations under this Agreement, but will remain responsible for all such obligations to the Client.
28. Interpretation. For avoidance of doubt, in this Agreement:
i) references to "this Agreement" include all amendments, additions and variations thereto;
ii) the headings and sub-headings are for convenience only and shall not affect the construction of this Agreement;
iii) unless the context otherwise requires the singular shall include the plural and vice versa;
iv) the words "other" and "otherwise" are not to be construed ejusdem generis with any foregoing words, and whenever the words "include", "includes" or "including" are used in this Agreement, they will be deemed to be followed by the words "without limitation";
v) references to Clauses are to clauses of this Agreement (unless otherwise specified); and
vi) any reference to a statute, statutory provision or other legislation includes, for the time being in force: (i) any order, regulation, instrument or other subordinate legislation made under it; and (ii) any amendment, extension, consolidation, re-enactment or replacement of it.</p>
    
    </div>
  );
}

export default TOS;
